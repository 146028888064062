import axios from "axios";

// const mainUrl = " http://localhost:8086/"; //Local

const mainUrl = "https://api.panoralands.in/"; //Local Network

export const baseUrl = `${mainUrl}api`;
export const imageUrl = mainUrl;
export const propertyurl = "https://www.panoralands.in/";
export const s3imageUrl =
  "https://panora-s3-bucket.s3.ap-south-1.amazonaws.com/";
export const jwt = localStorage.getItem("JWToken");
export const user = localStorage.getItem("role");

export const login = async (endPoint, raw) => {
  console.log(endPoint, raw);
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, raw)
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data?.message);
      });
  });
};

export const getProperty = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//FormData

// export const addProperty = async (endPoint, formData) => {
//     console.log(formData, "Log from helper")
//     return new Promise((resolve, reject) => {
//         axios.post(`${baseUrl}/${endPoint}`, formData, {
//             headers: {
//                 Authorization: jwt,
//                 'Content-Type': 'multipart/form-data' // Set proper content type for form data
//             }
//         }).then((result) => {
//             resolve(result.data);
//         }).catch((e) => {
//             reject(e.response?.data);
//         });
//     });
// };

//Request Body

export const addProperty = async (endPoint, params) => {
  console.log(params, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateProperty = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getPropertyByBM = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getPropertyTypes = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getCities = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};
export const getRentalImages = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {})
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

// Add Data
export const addRentalImage = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {})
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

// Update Data
export const updateRentalImage = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}/${endPoint}`, params)
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

// Delete Data (Soft delete or normal delete)
export const softDeleteRentalImage = async (endPoint, id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, { id })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getAreas = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};
export const getAreaByCity = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((e) => {
        console.log(e);
        reject(e.response?.data);
      });
  });
};

export const deleteProperty = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const signup = async (endPoint, raw) => {
  console.log(endPoint, raw);
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, raw)
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data?.message);
      });
  });
};

export const getStaff = async (endPoint, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, data, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};
export const getDashboard = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getStaffDashboard = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getBM = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteAdmins = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateUser = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getPropertyByID = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//City

export const addCity = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteCity = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateCity = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Property Type

export const addPropertyType = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getpropertyTypes = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updatePropertyType = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deletePropertyType = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//CMS testimonial

export const getTestimonialContent = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};
export const updateTestimonialContent = async (endPoint, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//WEb Content

export const getWebContent = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};
export const getContactContent = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateWebContent = async (endPoint, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//WEb Content

export const getHomeContent = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateHomeContent = async (endPoint, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Banners

export const getBanner = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addBanner = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateBanner = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteBanner = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateContectContent = async (endPoint, formData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Testimonials

export const getTestimonials = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteTestimonial = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addTestimonial = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateTestimonial = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

// staff by bm

export const getStaffByBM = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Authorized Partners

export const getPartners = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deletePartner = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addPartners = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updatePartners = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Top Builders

export const getBuilders = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteBuilder = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addBuilder = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateBuilder = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Why Choose Us

export const getWhyChooseUs = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteWhyChooseUs = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addWhyChooseUs = async (endPoint, params) => {
  console.log(params, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
          "Content-Type": "application/json", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateWhyChooseUs = async (endPoint, params) => {
  console.log(params, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
          "Content-Type": "application/json", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//

export const getListingTypes = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteListingType = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addListingType = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateListingType = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const getTeam = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const deleteTeam = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const addTeam = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateTeam = async (endPoint, formData) => {
  console.log(formData, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data", // Set proper content type for form data
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

// Terms

export const getTerm = async (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/${endPoint}`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

export const updateTerm = async (endPoint, data) => {
  console.log(data, "Log from helper");
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, data, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};

//Forgot Password

export const forgotPassword = async (endPoint, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/${endPoint}`, params, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => {
        reject(e.response?.data);
      });
  });
};
